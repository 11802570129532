import React from 'react'
import { Layout, Stack, Main } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Barra_bassa from '@widgets/Barra_bassa/Barra_bassa'
import ContactForm from '@widgets/ContactForm'
import PageTitle from '@components/PageTitle'

const cookie = props => {
  const { pageContext: { services = {} } = {} } = props

  return (
    <Layout {...props}>
      <Barra_bassa />

      <Seo title='Privacy' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Privacy'
          subheader=''
        />
      </Stack>

      <Divider />
      <Stack>
        <Main>
        <div>
            <h1>Politica sui cookie di eurorimborso.it</h1>
            <p>
              eurorimborso.it rispetta la privacy dell’utente e si impegna a proteggere ogni dato personale che l’utente potrebbe inviare tramite il nostro sito web. Le informazioni che seguono hanno lo scopo di aiutare l’utente a comprendere quali dati eurorimborso.it raccoglie attraverso il proprio sito web e come gli stessi vengono gestiti. Per ulteriori informazioni si prega di consultare la nostra Informativa sulla Privacy.
            </p>
            <p>
              Al fine di far funzionare il sito in modo efficiente, inseriamo cookie nei dispositivi dell’utente. Quasi tutti i grandi siti web fanno lo stesso. Accedendo, navigando e/o utilizzando il nostro sito web, l’utente conferma senza limitazioni o riserve di aver letto, compreso e accettato i termini e le condizioni della presente politica e, pertanto, acconsente all’utilizzo di cookie in conformità ai presenti termini.
            </p>
            <h3>Cosa sono i cookie?</h3>
            <p>
              I cookie sono piccoli file di testo che un sito web salva sul computer o sul dispositivo mobile dell’utente quando lo stesso visita un sito web. Consente al sito web di ricordare il dispositivo, le azioni e le preferenze dell’utente (quali lingua, dimensioni del font e altre preferenze di visualizzazione) per un certo periodo di tempo. I cookie possono essere utili, come nel caso delle preferenze, così l’utente non dovrà inserirle continuamente ogni volta che ritorna sul sito o naviga da una pagina all’altra.
            </p>
            <h3>Che tipi di cookie esistono?</h3>
            <p>
              I cookie possono essere classificati in base alla loro durata e al dominio a cui appartengono. Con riferimento alla durata, vi sono:
            </p>
            <ul>
              <li>cookie di sessione, i quali si eliminano automaticamente alla chiusura del browser web;</li>
              <li>cookie persistenti, i quali rimangono sul computer o sul dispositivo per un periodo di tempo prestabilito.</li>
            </ul>
            <p>Con riferimento al dominio di appartenenza, vi sono:</p>
            <ul>
              <li>cookie di prime parti, i quali vengono impostati direttamente da eurorimborso.it;</li>
              <li>cookie di terze parti, i quali sono conservati in un dominio diverso da quello della pagina web visitata.</li>
            </ul>
            <h3>Come utilizziamo i cookie?</h3>
            <p>
              Il nostro sito web utilizza i cookie per garantire una migliore esperienza all’utente. Utilizziamo sia i cookie di sessione sia cookie persistenti. L’abilitazione dei nostri cookie di prime e di terze parti non è strettamente necessaria per navigare all’interno del sito web, tuttavia, permette all’utente una migliore navigazione.
            </p>
            <p>
              Le informazioni relative ai cookie non vengono usate per identificare personalmente l’utente; inoltre, il percorso dei dati è totalmente in nostro controllo. Tutti i nostri cookie vengono utilizzati esclusivamente per lo scopo di seguito descritto.
            </p>
            <h3>Gestione dei cookie</h3>
            <p>
              L’utente può consentire, bloccare o eliminare i cookie installati sul proprio dispositivo facendo clic sul link seguente. Se l’utente non consente l’utilizzo di alcuni cookie, potrebbe non avere accesso a determinati servizi e la sua esperienza sul sito web potrebbe rivelarsi meno ottimale.
            </p>
            <p>Impostazioni cookie</p>
            <h3>Quali cookie utilizziamo?</h3>
            <p>
              Utilizziamo i nostri cookie di prime parti per ricordare le azioni e le preferenze dell’utente. Utilizziamo i nostri cookie di prime parti anche per aiutare l’utente a compilare i campi richiesti senza dover inserire più volte le medesime informazioni, in caso di navigazione da una pagina all’altra o di visite ripetute al sito.
            </p>
            <p>
              Utilizziamo i cookie di terze parti per migliorare l’esperienza di navigazione dell’utente attraverso analisi digitali e personalizzazioni in tempo reale. Utilizziamo altresì cookie di terze parti per scopi di pubblicità comportamentale online e per mostrare annunci relativi a qualcosa che l’utente ha già cercato in precedenza.
            </p>
            <h3>Cookie utilizzati nei siti eurorimborso.it</h3>
            <p>
              Questa politica si applica ai seguenti domini: eurorimborso.it, eurorimborso.com.
            </p>
            <h3>Elenco dei cookie</h3>
            <p>
              Un cookie è una piccola porzione di dati (file di testo) che un sito Web, se visitato da un utente, chiede al browser di memorizzarlo sul dispositivo per ricordare le sue informazioni, quali la lingua preferita o i dati di accesso. Questi cookie sono da noi impostati e denominati cookie di prima parte. Utilizziamo inoltre cookie di terza parte - ovvero i cookie di un dominio diverso da quello del sito Web che si sta visitando - per i nostri tentativi pubblicitari e di marketing. In particolare, utilizziamo i cookie e altre tecnologie di tracciamento per i seguenti scopi:
            </p>
            <h3>Cookie per pubblicità mirata</h3>
            <p>
              Questi cookie possono essere impostati tramite il nostro sito dai nostri partner pubblicitari. Possono essere utilizzati da queste aziende per costruire un profilo dei tuoi interessi e mostrarti annunci pertinenti su altri siti. Non memorizzano direttamente informazioni personali, ma sono basati unicamente sull'individuazione del tuo browser e del tuo dispositivo internet. Se non si accettano questi cookie, riceverai una pubblicità meno mirata.
            </p>
           
            
            <h3>Come controllare i cookie</h3>
            <p>
              L’utente può controllare e/o cancellare i cookie a proprio piacimento – per maggiori dettagli consultare la pagina aboutcookies.org.
            </p>
            <p>
              L’utente può cancellare tutti i cookie già presenti nel proprio computer e modificare le impostazioni del browser per impedire l’installazione di nuovi cookie. Tuttavia, l’eliminazione o il blocco di tutti i cookie potrebbe avrebbe un impatto negativo sulla stabilità di molti siti web.
            </p>
            <p>
              Se l’utente sceglie di bloccare i nostri cookie, lo stesso dovrà regolare manualmente le proprie preferenze ogni volta che visiterà il nostro sito web e, in aggiunta, alcuni servizi e funzionalità potrebbero non essere disponibili. Se l’utente desidera modificare le preferenze dei cookie di eurorimborso.it, può farlo facendo clic sul tasto “Impostazioni” sul banner che compare quando si vista il sito www.eurorimborso.it e che contiene le informazioni sui cookie.
            </p>
            <p>
              La presente politica potrebbe essere aggiornata periodicamente, ad esempio a seguito di modifiche sostanziali alla legislazione o alla struttura aziendale di eurorimborso.it. Invitiamo gli utenti a consultare la presente pagina regolarmente per restare informati sulle nostre ultime novità in merito alla politica sui cookie.
            </p>
            <p>Aggiornato in data: 02 Agosto 2024</p>
            <p>Versione CP1.24</p>
          </div>
        </Main>
      </Stack>
      <Stack>
        <Main>
          <ContactForm />
        </Main>
      </Stack>
    </Layout>
  )
}

export default cookie
